export const convertUTCToLocal = (utcHour, utcMinute, utcSecond) => {
  const utcDate = Date.UTC(1970,0,2, utcHour, utcMinute, utcSecond, 0); // milliseconds
  let currentDate = new Date();
  let offSet = currentDate.getTimezoneOffset() * 60000; // convert to milliseconds
  const localDate = new Date(utcDate - offSet);

  return {
    hour: localDate.getUTCHours(),
    minute: localDate.getUTCMinutes()
  };
};

export const convertCronToUserTime = (cronExpression) => {
  const [ second, minute, hour, dayOfMonth, month, dayOfWeek ] = cronExpression.split(' ');

  const localTime = convertUTCToLocal(
    parseInt(hour),
    parseInt(minute),
    parseInt(second),
  );

  const newHour = localTime.hour;
  const newMinute = localTime.minute;

  return `${second} ${newMinute} ${newHour} ${dayOfMonth} ${month} ${dayOfWeek}`;
};

export const convertLocalToUTC = (hour, minute, second) => {
  const localDate = new Date();
  localDate.setHours(hour, minute, second, 0);

  const utcDate = new Date(localDate.toLocaleString('en-US', { timeZone: 'UTC' }));

  return {
    hour: utcDate.getHours(),
    minute: utcDate.getMinutes()
  };
};

export const convertLocalTimeCronToUTC = (cronExpression) => {
  const [ second, minute, hour, dayOfMonth, month, dayOfWeek ] = cronExpression.split(' ');

  const utcTime = convertLocalToUTC(parseInt(hour), parseInt(minute), parseInt(second));

  const newHour = utcTime.hour;
  const newMinute = utcTime.minute;

  return `${second} ${newMinute} ${newHour} ${dayOfMonth} ${month} ${dayOfWeek}`;
};
